@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono&display=swap');

html, body {
  margin: 0;
  padding: 0;
  color: #343434;
  font-family: 'Ubuntu Mono', monospace;
  // background: #332421;
  font-size: 20px;
  background: #FCF9F4;
}

h1 {
  margin: 0;
  padding: 0;
  text-transform: capitalize;
  font-size: 30px;
}

a {
  color: inherit;
}

a:hover {
  color: #ff8484;
}

.container {
  display: flex;
  flex-direction: column;
  mix-blend-mode: darken;
  min-height: 100vh;
  background-image:
    radial-gradient(at 0% 0%, hsla(0,100%,75%,0.12) 0px, transparent 50%),
    radial-gradient(at 100% 100%, hsla(0,100%,75%,0.26) 0px, transparent 50%);
}

.disabled {
  pointer-events: none;
  color:#888888;
  cursor: not-allowed;
}

// static
.container::before {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url("/assets/images/static.webp");
  mix-blend-mode: darken;
  opacity: .02;
  z-index: -1;
}

.line {
  margin: 20px 0;
  align-self: center;
  height: 100px;
  border-left: 1px solid rgb(214, 214, 214);;
}

//navbar

.navbar {
  display: flex;
  width: 100%;
  height: 50px;
  background-color: inherit;
  justify-content: space-around;
  align-items: center;
  animation: .5s ease;
  flex-direction: row;
  font-size: 16px;
  font-weight: 900;
  transition: ease .3s;
  font-family: 'Ubuntu Mono', monospace;

  a {
    text-decoration: none;
    text-transform: uppercase;
  }

  .links a:not(:last-child) {
    margin-right: 15px;
  }

}

.scrolling-active {
  background-color: #FCF9F4 !important;
  position: fixed;
  transition: .3s ease-in-out;
  color: inherit;
  box-shadow: 0 0 10px #D1D1D1;
}

//components
.landing, .projects, .contact, .resume, .page-404 {
  margin: 0;
  padding: 0;
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

//landing
.landing {
  flex-direction: column;

  h1 {
    font-size: 60px;
    margin-bottom: 30px;
  }

  .landing-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 800px;

    .section {
      width: 100%;
      display: inherit;
      flex-direction: row;

      .tag {
        width: 25%;
        justify-content: flex-end;
        display: flex;

        span {
          margin-right: 30px;
        }
      }

      .about, .tools, .languages {
        width: 65%;
        word-wrap: break-word;
        word-break: keep-all;
      }

      .tools, .languages {
        span {
          margin-right: 10px;
          text-transform: capitalize;
        }
      }
    }
  }

  .landing-inner .section:not(:last-child) {
    margin-bottom: 30px;
  }
}

//projects
.projects {
  flex-direction: column;
  margin: 20px 0;

  h1 {
    margin-top : 50px;
  }

  .project-even, .project-odd {
    margin: 0 10%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .project-inner {
      width: 50%;
      
        h1 {
          margin: 10px 0;
          font-size: 25px;
        }

        .img-wrapper {
          display: flex;
          height: auto;
          width: 100%;
          flex-direction: column;
          box-shadow: 0 0 10px .5px rgb(214, 214, 214);
    
          img {
            height: auto;
            width: 100%;
            object-fit: contain;
          }
        }

        .description {
          display: flex;
          margin: 10px 0;
          justify-content: space-between;

          .stack {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            span {
              margin-right: 10px;
              align-content: center;
              transform: none;
              position: unset;
              color: unset;
              text-transform: capitalize;
              font-size: 16px;
            }
          }

          .description-links {
            display: flex;
            justify-content: space-between; 
            margin: auto 0;
            font-size: 18px ;

            a {
              font-size: 18px;
            }
          }

          .description-links a:not(:last-child) {
            margin-right: 10px;
          }
        }

      }
    }


  .project-odd {
    justify-content: flex-end;
    text-align: end;
  }

  .project-even {
    justify-content: flex-start;
    text-align: start;
  }
}

//footer
.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 50px;

  span {
    align-self: center;
    font-style: italic;
    font-size: 12px;
  }
}

//contact
.contact {
  flex-direction: column;

  p {
    width: 100%;
    margin: 0 0 15px 0;
  }


  .contact-inner{
    width: 50%;
    display: inherit;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    form {
      width: 100%;
      display: inherit;
      flex-direction: column;

      .form-input {
        display: inherit;
        width: inherit;

        textarea {
          resize: none;  
          height: 300px;
          width: 100%;
        }

        textarea, input {
          font-family: 'Noto Sans JP', sans-serif;
          padding: 5px 10px;
          margin: 10px 0;
          border: none;
          box-shadow: 0 0 10px .5px rgb(233, 233, 233);
        }

        textarea:focus, input:focus {
          transition: ease-in .2s;
          outline: none;
          box-shadow: 0 0 10px .5px #c4c4c4;
        }

        textarea::placeholder, input::placeholder {
          font-family: 'Noto Sans JP', sans-serif;
        }

        button {
          font-family: inherit;
          font-size: 12px;
          border: none;
          margin: 10px 0;
          width: 90px;
          height: 30px;
          color: white;
          background-color: #585858;
        }

        button:hover {
          opacity: .8;
          cursor: pointer;
        }
      }

      .name-email {
        justify-content: space-between;

        input {
          height: 30px;
          width: 45%;
        }
      }

    }
  }
}

@media (max-width: 950px) {
  html, body {
    font-size: 14px;
  }

  .navbar {
    font-size: 12px !important;
  }

  h1 {
    font-size: 20px;
  }

  .project-even,.project-odd {
    justify-content: center !important;

    .project-inner {
      width: 100% !important;

      .description {
        .stack {     
          span {
            font-size: 12px !important;
          }
        }

        a {
          font-size: 12px !important;
        }
      }
    }
  }

  .contact-inner {
    width: 80% !important;

    .name-email {
      flex-direction: column !important;

      input {
        width: unset !important;
      }
    }
  }

  .landing-inner {
    width: 80% !important;
  }
}

@media (max-width: 1500px) {
  .navbar {
    font-size: 14px !important;
  }

  .project-inner{
    width: 60% !important;
  }
}

//photos

.photo-nav {
  width: 100%;
  display: flex;
  width: 100%;
  height: 50px;
  background-color: transparent;
  align-items: center;
  justify-content: space-around;
  color: #585858;
  font-weight: 900;

  .logo, .links {
    transition: ease 5s;
  }
}

.photos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .photo {
    width: 80%;
    height: 100%;
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 10px;

    .photo-wrapper {
      display: flex;
      height: 100%;
      width: 75%;
      flex-direction: column;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }

      .description {
        margin: 15px 0;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}